import React from "react"
import { Box, Flex, Link, ListItem, UnorderedList } from "@chakra-ui/react"

const Footer = ({ socialLinks }) => (
  <Box as="footer">
    <Flex as={UnorderedList} mx={0} my={7}>
      {socialLinks.map(link => (
        <ListItem key={link.name}
                  mr={3}
                  listStyleType="none">
          <Link href={link.url} target="_blank">{link.name}</Link>
        </ListItem>
      ))}
    </Flex>
  </Box>
)
export default Footer
