import React from "react"

import SEO from "../../components/seo"
import PostList from "gatsby-theme-blog-core/src/components/post-list"
import Footer from "../../components/home-footer"
import Layout from "../../components/layout"
import { Box, Divider } from "@chakra-ui/react"
import Bio from "../../components/bio"

const Posts = ({ location, data }) => {
  const {
    site: {
      siteMetadata: {
        title: siteTitle,
        social: socialLinks
      }
    }, allBlogPost: {
      nodes: posts
    }
  } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <Box
        my={10}
        as="header">
        <Bio />
        <Divider />
      </Box>
      <main>
        <PostList posts={posts} />
      </main>
      <Footer socialLinks={socialLinks} />
    </Layout>
  )
}

export default Posts
