import React from "react"
import { Box, Heading, Text } from "@chakra-ui/react"
import Link from "../../components/link"
import PostTags from "../../components/post-tags"

const PostLink = ({ title, slug, tags, excerpt }) => (
  <Box as="article" mb="8" shadow="lg" px="4" py="2">
    <Box as="header">
      <Heading
        as="h2"
        size="md"
      >
        <Link
          sx={{
            textDecoration: `none`
          }}
          to={slug}
        >
          {title || slug}
        </Link>
      </Heading>
    </Box>
    <Box as="section" mb={4} mt="3">
      <PostTags tags={tags} />
    </Box>
    <section>
      <Text>{excerpt}</Text>
    </section>
  </Box>
)

export default PostLink
